var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page privacy_p" },
    [
      _c("h2", { staticStyle: { "line-height": "1.5" } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._l(_vm.contentArr, function(item, index) {
        return _c("p", { key: index }, [
          _c("b", [_vm._v(_vm._s(item.title))]),
          _c("span", [_vm._v("\n      " + _vm._s(item.content) + "\n    ")])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }